import React from "react"
import * as styles from "./Footer.module.css"

const Footer = () => {
  return (
    <div className={styles.footerBg}>
      <div className={styles.footerContainer}>
        <h3 className={styles.footerSubTitle}>
          Submitting feedback or other inquiries?
          <a
            className={styles.footerHireMe}
            target="_blank"
            href="mailto:info@dtcgo.co"
          >
            Contact Us
            <svg
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              height="20"
              aria-labelledby="envelopeAltIconTitle"
              stroke="hsla(221, 70%, 30%, 1)"
              stroke-width="1"
              stroke-linecap="square"
              stroke-linejoin="miter"
              fill="none"
              color="hsla(221, 70%, 30%, 1)"
            >
              {" "}
              <title id="envelopeAltIconTitle">Envelope</title>{" "}
              <rect width="20" height="14" x="2" y="5" />{" "}
              <path stroke-linecap="round" d="M2 5l10 9 10-9" />{" "}
            </svg>
          </a>
        </h3>
        <a
          className={styles.footerAttr}
          target="_blank"
          href="https://icons8.com"
        >
          {" "}
          illustration by Ouch.pics
        </a>
      </div>
    </div>
  )
}

export default Footer
