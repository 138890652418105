import React,{ useRef } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as styles from "./EmailSignUp.module.css"

const EmailSignUp = () => {
  const formEl = useRef(null);
  const doHtmlFormPost = values => {
    formEl.current.submit();
  };
    const formik = useFormik({
        initialValues: {
          EMAIL: '',
        },
        validationSchema: Yup.object({
          EMAIL: Yup
          .string()
          .email()
          .required('Required')
        }),
        onSubmit: values => {
          console.log(values)
          doHtmlFormPost()
    
        },
      });
    return (
        <form className={styles.ctaContainer} method="POST" onSubmit={formik.handleSubmit} ref={formEl} action='https://c375da00.sibforms.com/serve/MUIEACHRkgONuVgl4MT5oKNNXMe4oERvKHPRbfdhzA-HohC6d1zex_2c7K9j__QflJCAi9pGzeY4tov-KWOm-rLKWBxgl5ixtottBxYkJ7IMN5iEtobEQjxWJCw87ikY3JkcbGoMZtwkbJND2ueOeKdu1B0QGmwrnw8di-t92-MYLbVztMAe9kmFz_iPQKjV0Rdo-VoOEUQtVUdf'>
        <input
           id="EMAIL"
           name="EMAIL"
           type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.EMAIL}
          className={styles.ctaInput}
          placeholder="Enter your email address"
         />
          {formik.touched.EMAIL && formik.errors.EMAIL ? (
            <div>{formik.errors.EMAIL}</div>
          ) : null}
         <button type="submit" className={styles.ctaButton}>Download PDF</button>
       </form>
    )
}

export default EmailSignUp
