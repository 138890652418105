import React, { useState, useEffect } from "react"
import EmailSignUp from "../emailSignUp/EmailSignUp"
import * as styles from "./CtaCard.module.css"
import { useStaticQuery, graphql } from "gatsby"

const CtaCard = () => {
  

  return (
      <div className={styles.callToActionBg}>
        <div className={styles.callToActionContainer}>
          <h2 className={styles.ctaTitle}> Free DTC Checklist </h2>
          <h3 className={styles.ctaSubTitle}>
            A summarized checklist PDF for DTC merchants
          </h3>
          <h3 className={styles.ctaSubTitle}>
            With periodic updates on new content, no spam
          </h3>
          <div className={styles.ctaEmailContainer}>
            <EmailSignUp/>
          </div>
        
        </div>
      
      </div>
  )
}

export default CtaCard
